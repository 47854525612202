import React, {useRef} from 'react';
import {
  Box,
  Button,
  Popover,
  StatusIndicator
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import {TYPE} from "./history";

function Message({ item, onSelect }) {
  const ref = useRef();

  const bubble = (ref, color, item, onSelect) => {
    return (
      <div ref={ref}
           style={{
             padding: '10px',
             borderRadius: '10px',
             backgroundColor: color,
             cursor: item.type !== TYPE.ALEXA ? 'pointer' : 'default'
           }}
           onClick={(event) => {
             if (event.target === ref.current && item.type !== TYPE.ALEXA) {
               onSelect(item);
             }
           }}
      >
        {item.content}
        <Popover
          size="medium"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={
            <StatusIndicator type="success">
              Copied to clipboard
            </StatusIndicator>
          }
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => {
              navigator.clipboard.writeText(item.content);
            }}
          />
        </Popover>
      </div>
    );
  }

  const pending = (item) => {
      return (<StatusIndicator type="loading">{item.content}</StatusIndicator>)
  }

  const failed = (item) => {
      return (<StatusIndicator type="error">{item.content}</StatusIndicator>)
  }

  const processing = (item) => {
      return (<StatusIndicator colorOverride="green"  type="in-progress">{item.content}</StatusIndicator>)
  }

  return (
    <div>
      {item.type === TYPE.ALEXA && (
        <Box float="right">
          {bubble(ref, "#f1faff", item, onSelect)}
        </Box>
      )}
      {item.type === TYPE.HUMAN && (
        <Box>
          {bubble(ref, "#f2f8f0", item, onSelect)}
        </Box>
      )}
      {item.type === TYPE.PENDING && (
        <Box>
            {pending(item)}
        </Box>
      )}
      {item.type === TYPE.FAILED && (
        <Box>
            {failed(item)}
        </Box>
      )}
      {item.type === TYPE.PROCESSING && (
        <Box>
            {processing(item)}
        </Box>
      )}
    </div>
  );
}

export default React.memo(Message);
