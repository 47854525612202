import he from 'he';

export const params = (dsn, type, overrides = {}) => {
    const params = new URLSearchParams();
    params.append('deviceserial', dsn);
    params.append('devicetype', type);
    Object.entries(overrides).forEach(([key, value]) => params.append(key, value));
    return params;
};

const prosodyRegex = /(<prosody.*?>)(.*?)(<\/prosody>)/g;
export const text = (ssml) => {
    let match, sb = '';
    let decoded = he.decode(ssml);
    // Add he
    while ((match = prosodyRegex.exec(decoded)) !== null) {
        sb += match[0];
    }
    return (sb || decoded).replace(/<[^>]*>/g, '');
}
