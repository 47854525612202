import React, {useEffect, useState} from 'react';
import AWSUI from '@amzn/awsui-components-react';
import {
  Box,
  StatusIndicator,
  Table,
  SpaceBetween,
  Header,
  Icon,
  Button
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

function DeviceSideBar({ devices, onSelect }) {
  const [selectedOption, setSelectedOption] = useState([]);
  const [where, setWhere] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const state = async () => {
        if (devices && devices.length > 0) {
            const unsub = [];
            for (const d of devices) {
                if (!where[d.dsn]) {
                    unsub.push(d.subscribe(stateChange));

                    await d.refresh();
                    let dW = d.status();
                    setWhere(w => ({
                        ...w,
                        [d.dsn]: dW
                    }));
                }
            }

            return () => unsub.forEach(u => u());
        }
    }
    state();
  }, [devices]);

  const stateChange = (d) => {
      setWhere(w => ({
          ...w,
          [d.dsn]: d.status()
      }));
  }

  const statusIndicator = (dsn) => {
      if (where[dsn]) {
          const proxy = where[dsn].proxy;
          return (
            <SpaceBetween direction="horizontal" size="s">
              <Icon name={ proxy ? "status-positive" : "status-stopped" } variant={ proxy ? "success" : "subtle" } />
              <Box variant="awsui-key-label">OAK Proxy Connect</Box>
            </SpaceBetween>
          )
      } else {
          return (<StatusIndicator type={"loading"}> Loading </StatusIndicator>);
      }
  }

  const status = (dsn) => {
    if (where[dsn] && where[dsn].online) {
      return (<Icon name="status-positive" variant="success" />);
    } else {
      return (<Icon name="status-negative" variant="subtle" />);
    }
  }

  const onChange = ({detail}) => {
    setSelectedOption(detail.selectedItems);
    onSelect(detail.selectedItems[0]);
  }

  const action = () => {
      let dsn = selectedOption.length > 0 ? selectedOption[0].dsn : '';
      return {
        offline: () => !where[dsn] || !where[dsn].online,
        connected: () => where[dsn] && where[dsn].proxy
      }
  }

  const devicecall = (call) => {
    if (!loading) {
      setLoading(true);
      const doit = async () => {
        await call();
        onSelect(selectedOption[0]);
        setLoading(false);
      }
      doit();
    }
  }

  const connect = (env) => {
    if (env == "gamma") {
      window.alert("Use this option to connect to Alexa Gamma without devonizing the device! OAK proxy Gamma is currently not available!")
    }
    selectedOption[0].env = env;
    devicecall(selectedOption[0].connectandsanity);
  }

  const disconnect = () => {
    devicecall(selectedOption[0].disconnect);
  }

  const refresh = () => {
    for (const d of devices) {
      devicecall(d.refresh);
    }
  }

  let act = action();
  let connected = act.connected();
  let offline = act.offline();

  const ENDPOINTS = [
    {
      text: "OAK Endpoints",
      items: [
        { text: "Prod", id: "prod"}
      ]
    },
    {
      text: "Alexa Endpoints",
      items: [
        { text: "Gamma", id: "gamma"}
      ]
    }
  ]

  return (
    <Table
        wrapLines
        onSelectionChange={onChange}
        selectedItems={selectedOption}
        isItemDisabled={(i) => loading || (where[i.dsn] && !where[i.dsn].online)}
        columnDefinitions={[
          {
            id: "dsn",
            cell: item => (
                <SpaceBetween size="s">
                  <Box color="text-status-info">{item.name}</Box>
                  <SpaceBetween direction="horizontal" size="s">
                    {status(item.dsn)}
                    <Box variant="awsui-key-label">{item.dsn}</Box>
                  </SpaceBetween>
                  { statusIndicator(item.dsn) }
                </SpaceBetween>
            ),
            header: (
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            <Box padding="xxxs">
                                <AWSUI.ButtonDropdown loading={loading} disabled={offline ? true : connected ? true : false}
                                        items={ ENDPOINTS }
                                        onItemClick={(event) => {connect(event.detail.id)}}>
                                  Connect
                                </AWSUI.ButtonDropdown>
                                <AWSUI.Button loading={loading} disabled={offline ? true : connected ? false : true}
                                        onClick={() => disconnect()}>
                                  Disconnect
                                </AWSUI.Button>
                            </Box>
                            <Button iconName="refresh" variant="icon" loading={loading} onClick={refresh}/>
                        </SpaceBetween>
                    }
                    >
                    Devices
                </Header>
            )
          }
        ]}
        columnDisplay={[
          { id: "dsn", visible: true }
        ]}
        items={devices}
        loading={!devices || !devices.length > 0}
        selectionType="single"
        trackBy={i => i.dsn}
        stickyColumns={{ first: 0, last: 1 }}
        variant="borderless"
        contentDensity="compact"
    />
  )
}

export default React.memo(DeviceSideBar);
