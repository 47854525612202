import React, {useEffect, useState} from "react";
import {
    SpaceBetween,
    Button,
    FormField,
    Box,
    Toggle,
    Modal,
    Select,
    Textarea
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

const DEFAULT_PROMPT = `The input is the conversation history between a human denoted by Q: and an AI denoted by A:.
Generate a response for Q: to continue the conversation set in the input.
Response should be a question tangential to the input.
Consider the response is for the AI bot.
Response should be between 6 words and 10 words.
Response should be a fully complete question.
Response should not ask about weather.
Response should be extremely random.
Response should elicit an answer.
Response should be strictly only the question text without denoting who.
If there is no input or input is not comprehensible, respond with let's chat.
`;

const DEFAULT_MODEL = { label: "Claude Instant V1", value: "anthropic.claude-instant-v1" };

function Suggest({ settings, mode }) {
    const [suggest, setSuggest] = useState(false);
    const [auto, setAuto] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [prompt, setPrompt] = React.useState(DEFAULT_PROMPT);
    const [selectedOption, setSelectedOption] = React.useState(DEFAULT_MODEL);
    const [userIn, setUserIn] = React.useState({option: DEFAULT_MODEL, prompt: DEFAULT_PROMPT});

    useEffect(() => {
        settings({option: selectedOption, prompt: prompt});
    }, [selectedOption, prompt]);

    useEffect(() => {
        mode({suggest: suggest, auto: auto});
    }, [suggest, auto])

    const handleSuggest = (e) => {
        setSuggest(e.detail.checked);
        !e.detail.checked && setAuto(false);
    }

    const handleAuto = (e) => {
        setAuto(e.detail.checked);
    }

    return (
        <SpaceBetween direction="vertical" size="xs" className="awsui-util-pb-xs">
            <Toggle
                checked={suggest}
                onChange={handleSuggest}
            >
                Suggest
            </Toggle>
            <FormField stretch
                label={<Box color="text-body-secondary" variant="strong">Options</Box>}
            >
                <SpaceBetween direction="horizontal" size="l">
                    <Modal
                        onDismiss={() => {
                            setVisible(false);
                            setUserIn({option: selectedOption, prompt: prompt});
                        }}
                        visible={visible}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="primary" onClick={() => {
                                        setSelectedOption(userIn.option);
                                        setPrompt(userIn.prompt);
                                        setVisible(false);
                                    }}>
                                        Save
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Suggest Options"
                    >
                        <SpaceBetween direction="vertical" size="m">
                            <FormField stretch
                                       label={<Box color="text-body-secondary" variant="strong">Model</Box>}
                            >
                                <Select
                                    disabled={false}
                                    selectedOption={userIn.option}
                                    onChange={({ detail }) =>
                                        setUserIn({...userIn, option: detail.selectedOption})
                                    }
                                    options={[
                                        DEFAULT_MODEL
                                    ]}
                                />
                            </FormField>
                            <FormField stretch
                                       label={<Box color="text-body-secondary" variant="strong">Prompt</Box>}
                            >
                                <Textarea
                                    disableBrowserAutocorrect
                                    disableBrowserSpellcheck
                                    onChange={({ detail }) =>
                                        setUserIn({...userIn, prompt: detail.value})
                                    }
                                    value={userIn.prompt}
                                    placeholder="Enter prompt here"
                                    rows={20}
                                />
                            </FormField>
                            <FormField stretch
                                       label={<Box color="text-body-secondary" variant="strong">Input</Box>}
                            >
                                <Box>
                                    The conversation history (5 recent exchange) will be appended to the prompt<br/>
                                    <pre>Q:<br/>
                                    A:<br/>
                                    ....<br/>
                                    Q:<br/>
                                    A:<br/></pre>
                                </Box>
                            </FormField>
                        </SpaceBetween>
                    </Modal>
                    <Button
                        onClick={() => setVisible(true)}
                        disabled={!suggest}
                        href="#"
                        variant="inline-link"
                        iconName="edit"
                    >
                        {selectedOption.label}
                    </Button>
                    <Toggle
                        disabled={!suggest}
                        checked={auto}
                        onChange={handleAuto}
                    >
                        Auto Bot
                    </Toggle>
                </SpaceBetween>
            </FormField>
        </SpaceBetween>
    );
}

export default React.memo(Suggest);
