import _ from 'lodash';
import { params } from "./util";
import { HexClient, AbesClient } from './client';
import { logToConsole } from "../../Util";
import { AvsApi } from "./avsapi";
import { History } from "./history";
import { v4 as uuidv4 } from 'uuid';

const PROXY_ENDPOINTS = {
    "gamma": {
        "us-east-1": {endpoint: "https://bob-dispatch-gamma-na.amazon.com" }
    },
    "prod": {
        "us-east-1": {endpoint: "https://avsqa.amazon.com" }
    }      
}
 
const PROD_ENDPOINTS = {
    "gamma": {
        "us-east-1": {endpoint: "https://bob-dispatch-gamma-na.amazon.com" }
    },
    "prod": {
        "us-east-1": {endpoint: "https://bob-dispatch-prod-na.amazon.com" }
    }      
}
 
export function Device(customerId, deviceInfo) {
    let status = {};
    let beep = false;
    let poll = null;
    let subscribers = [];

    this.customerId = customerId;
    this.dsn = _.get(deviceInfo, 'dsn');
    this.type = _.get(deviceInfo, 'deviceType');
    this.name = _.get(deviceInfo, 'name');
    this.status = () => { return {...status} };
    this.beep = () => beep;
    this.env = "prod";

    let history = new History(this.dsn, this.type);
    this.history = () => history;
    let avsapi = new AvsApi(this.dsn, this.type, history);
    this.avsapi = () => avsapi;

    const updatestatus = (loc, proxy, online) => {
        const current = { ...status };
        status = { loc, proxy, online };
        if (!_.isEqual(current, status)) {
            publish();
        }
    }

    const where = async () => {
        const query = params(this.dsn, this.type);
        try {            
            const resp = await HexClient.deviceendpoint(query, "prod");            
 
            if (_.get(resp, 'status') === 200) {
                const loc = _.get(resp, 'data.Endpoint');
                const proxy = loc.includes('avsqa');
                updatestatus(loc, proxy, true);                  
                return;
            }
        } catch (err) {
            try {
                const resp = await HexClient.deviceendpoint(query, "gamma");                
                if (_.get(resp, 'status') === 200) {
                    const loc = _.get(resp, 'data.Endpoint');
                    const proxy = loc.includes('avsqa');
                    updatestatus(loc, proxy, true);
                    this.env = "gamma";
                    return;
                }
            } catch(err) {
                if (!/409|404/.test(err.message)) {
                    logToConsole(`Cannot get device location ${err}`);
                }
            }
        }
        updatestatus('', false, false);
    };

    const publish = () => {
        subscribers.forEach(s => s(this));
    };

    const endpoint = async (endpoint) => {
        if (status.online) {
            try {
                const query = params(this.dsn, this.type, {'endpoint': endpoint});
                const resp = await HexClient.switchendpoint(query);
                await new Promise(resolve => setTimeout(resolve, 5000));

                if (_.get(resp, 'status') === 204) {
                    await where();
                }
            } catch (err) {
                logToConsole(`Cannot switch device location ${err}`);
            }
        }
    }

    const dobeep = async (state) => {
        try {
            const payload = {
                addToFeature: state,
                deviceSerialNumber: `${this.dsn}_CHAT`,
                customerId: this.customerId,
                feature: "muffle_response"
            }
            const resp = await AbesClient.sdcfeature(payload);

            if(_.get(resp, 'status') === 200 &&
              _.get(resp, 'data', []).some(s => s.includes("SUCCESS"))) {
                beep = state
                return;
            }
        } catch (err) {
            logToConsole(`Cannot do beep for device ${err}`);
        }
        beep = false;
    }

    const sanitycheck = async () => {
        try {
            // Verify utterance injection
            const start = Math.floor(Date.now() / 1000);
            if (status.proxy && await this.injectspeech('Simon says open automation kit connected', false)) {
                await new Promise(resolve => setTimeout(resolve, 10000));

                const resp = await HexClient.activityitems(params(this.dsn, this.type, {'starttime': start, 'endtime': start + 10}));

                return _.chain(_.get(resp, 'data.ActivityItems', {}))
                  .some(item => JSON.parse(item[1].ActivityItemData).intentType === 'EchoIntent')
                  .value();
            }
        } catch (err) {
            logToConsole(`Cannot inject utterance on the device ${err}`);
        }
        return false;
    }

    const senddirective = async (data) => {
        try {
            const payload = {                
                deviceType: this.type,
                deviceId: this.dsn,
                customerId: this.customerId,
                payload: data
            }            
            await AbesClient.senddirective(payload);                                    
            await where();
                                        
        } catch (err) {
            logToConsole(`SendDirective to device failed ${err}`);
        }        
    }

    this.suggest = async (prompt, modelid) => {
        if (status.proxy) {
            try {
                let hist = this.history()
                    .featureItems()
                    .slice(-10)
                    .map((step) => `Q: ${step.Q}\nA:${step.A}`)
                    .join('\n');
                let params = new URLSearchParams();
                params.append("prompt", prompt);
                params.append("modelid", modelid);
                params.append("stmt", hist);

                const resp = await HexClient.bedrockgenerate(params);

                if (_.get(resp, 'status') === 200) {
                    return _.get(resp, 'data.response');
                }
            } catch (err) {
                logToConsole(`Cannot generate a suggestion ${err}`);
            }
            return false;
        }
    }

    this.initialize = async () => {
        if (poll) return;
        await where();
        poll = setInterval(async () => {
            let current = { ...status };
            await where();
            if (!_.isEqual(current, status)) {
                publish();
            }
        }, 60000);
    };

    this.subscribe = (callback) => {
        subscribers.push(callback);

        // Return unsubscribe
        return () => {
            const index = subscribers.indexOf(callback);
            if (index !== -1) {
                subscribers.splice(index, 1);
            }
        };
    };

    this.injectspeech = async (text, addhistory = true) => {
        if (status.proxy) {
            return await avsapi.injectspeech(text, this.env, addhistory)
        }
        return false;
    }

    this.startstream = async () => {
        if (status.proxy) {
            await avsapi.initialize();
        }
    }

    this.endstream = async () => {
        await avsapi.clear();
    }

    this.connect = async () => {
      if (status.online && !status.proxy) {
          await dobeep(true);
          let url = PROXY_ENDPOINTS["prod"]["us-east-1"].endpoint;          
          await endpoint(url);          
          if(this.env == "gamma") {            
            const payload = {                
                directive: {
                    header: {
                        namespace: "Alexa.ApiGateway",
                        name: "SetGateway",
                        messageId: uuidv4(),
                    },
                    payload: {
                        gateway: PROXY_ENDPOINTS["gamma"]["us-east-1"].endpoint
                    }                    
                }
            }
            await senddirective(JSON.stringify(payload));
          }
          await this.startstream();
      }
      publish();
      return this.status();
    }

    this.connectandsanity = async () => {        
        await this.connect();
        if (status.proxy && !await sanitycheck()) {
            await this.disconnect();
            window.alert("Connection to OAK failed!!!")
        }
        return this.status();
    }

    this.disconnect = async () => {
        if (status.online && status.proxy) {
            await dobeep(false);
            await this.endstream();
            if(this.env == "gamma") {                
                const payload = {                
                    directive: {
                        header: {
                            namespace: "Alexa.ApiGateway",
                            name: "SetGateway",
                            messageId: uuidv4(),
                        },
                        payload: {
                            gateway: PROXY_ENDPOINTS["prod"]["us-east-1"].endpoint
                        }                                            
                    },
                    isGammaDevice: true
                }
                await senddirective(JSON.stringify(payload));
              }
            await endpoint("https://bob-dispatch-prod-na.amazon.com");
        }
        publish();
        return this.status();
    }

    this.refresh = async () => {
        await where();
        await this.endstream();
        await this.startstream();
    }

    this.clear = async () => {
        poll && clearInterval(poll);
    };
}
