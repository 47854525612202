
export const feature = (steps) => {
    return `
Feature: Alexa Conversation

  @connect
  Scenario: Connect to OAK
    * SWITCH ENDPOINT TO "AQTProxy" FOR "dut1"

  @execute
  Scenario: Run exported utterances
    * Inject utterance and match response on "dut1"
${steps.map((step) => `      | ${step.Q} |  |`).join('\n')}

  @disconnect
  Scenario: Disconnect from OAK
    * SWITCH ENDPOINT TO "BOB" FOR "dut1"
    * PLAY "Alexa, stop" WITH FILENAME "en_US_stop.wav" ON "pi1" AS "P1"
`;
}
