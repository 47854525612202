import React, {useEffect, useState} from 'react';
import { HexClient as client } from './client';
import Chat from "./chat";
import DeviceSideBar from "./deviceSideBar";
import {
  Grid,
  Container,
  Alert,
  Box
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Api from "./api";
import * as ld from 'lodash';
import { Device } from "./device";

function Playground() {
  const [corpAccess, setCorpAccess] = useState(null);
  const [account, setAccount] = useState({});
  const [activeDevice, setActiveDevice] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    let ping = async () => {
      try {
        if (corpAccess === null) {
          let resp = client.ping();
          setCorpAccess(ld.get(await resp, 'status', '') === 200);
        }
      } catch (err) {
        setCorpAccess(false);
        setErrors([...errors, 'Cannot verify corp connectivity to OAK']);
      }
    }
    ping();
  });

  useEffect(() => {
    if (corpAccess && !ld.get(account, 'account.devices', []).length > 0) {
      let info = async () => {
        try {
          let resp = await client.deviceaccounts();
          let customerId = ld.get(resp, "data.CustomerId", '');
          let devicesReg = ld.get(resp, "data.RegisteredDevices", []);
          if (customerId && devicesReg) {
            let devices = [];
            devicesReg.map(item => {
              let device = new Device(customerId, item);
              devices.push(device);
            });
            setAccount({ customerId, devices });
          } else {
            throw Error('Device accounts info unavailable');
          }
        } catch (err) {
          setErrors([...errors, 'Device accounts info unavailable'])
        }
      }
      info();

      // Cleanup polling on component unload
      return () => {
        if (account.devices && account.devices.length > 0) {
          account.devices.forEach(device => device.clear());
        }
      }
    }
  }, [corpAccess]);

  return (
    !corpAccess ? (
        <Alert type="warning">Access to Playground requires Corp access and CID allowlisted in OAK</Alert>
    ) : (
        <Container
            disableContentPaddings
            media={{
              content: (
                  <div id="playground" style={{
                    height: '91vh',
                    overflowY: 'auto',
                    border: '1px solid #d5dbdb'
                  }}>
                    <DeviceSideBar devices={account.devices} onSelect={d => setActiveDevice({...d})}/>
                  </div>
              ),
              position: "side",
              width: "400px"
            }}
        >
          <div id="playground" style={{
            height: '91vh',
            overflowY: 'auto',
            border: '1px solid #d5dbdb'
          }}>
            { activeDevice && activeDevice.status ? (
                <div>
                  { activeDevice.status().proxy ? (
                      <Grid disableGutters gridDefinition={[ {colspan: 7}, {colspan: 5} ]}>
                        <Chat device={activeDevice}/>
                        <Api device={activeDevice}/>
                     </Grid>
                    ) : (
                      <Box margin="xxl" padding="xxl" fontSize="heading-m">
                        Connect the device to OAK by clicking on the Connect button
                      </Box>
                    )
                  }
                </div>
              ) : (
                <Box margin="xxl" padding="xxl" fontSize="heading-m">
                  Select a device to start
                </Box>
              )
            }
          </div>
        </Container>
    )
  );
}

export default React.memo(Playground);
