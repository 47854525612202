import React, {useState, useEffect} from 'react';
import ReactJson from '@microlink/react-json-view';
import { HexClient as client } from './client'
import * as ld from 'lodash';
import {
    SpaceBetween,
    Select,
    Container,
    FormField
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { params } from "./util";
import { logToConsole } from "../../Util";

function Metadata({ item, device }) {
    const [activity, setActivity] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [utteranceId, setUtteranceId] = useState(null);
    const [
        selectedOption,
        setSelectedOption
    ] = React.useState(null);

    useEffect(() => {
        const setVal = async () => {
            if (item) {
                const activities = await getActivity(item);
                setActivity(activities);
                setMetadata(activities);
                setSelectedOption({ label: "AFS_ACTIVITY_ITEMS", value: "AFS_ACTIVITY_ITEMS" });
                if (activities) {
                    setUtteranceId(ld.chain(
                        ld.get(activities, "ActivityItems", {}))
                        .map(i => i[0].UtteranceId).value()
                    );
                }
            }
        };
        setVal();
    }, [item]);

    const getActivity = async (item) => {
        if (item) {
            let param = params(device.dsn, device.type, {
                'starttime': item.starttime,
                'endtime': item.starttime + 7,
                'limit': 1
            });
            let activities = await client.activityitems(param);
            return activities.data;
        }
        return {};
    }

    const getMetadata = async (forType) => {
        if (forType) {
            let param = params(device.dsn, device.type, {
                contenttype: forType,
                approximateinjectiontime: item.starttime,
                datatype: "DATA",
                utteranceid: utteranceId
            });
            let bytedata;
            try {
                bytedata = await client.alexametadata(param);
            } catch (err) {
                logToConsole(err);
            }
            bytedata = ld.get(bytedata, "data.ColdplayByteData.payload", {"warn": "cannot get data at this time, please retry after sometime"});
            return ld.get(bytedata, "warn") ? bytedata : forType === "INTENT" ? atob(bytedata) : JSON.parse(atob(bytedata));
        }
    }

    const handleSelectedOption = async (option) => {
        if (option.value === "AFS_ACTIVITY_ITEMS") {
            setMetadata(activity);
        } else {
            let data = await getMetadata(option.value);
            setMetadata({data: data});
        }
        setSelectedOption(option);
    }

    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label={<strong>Metadata</strong>}
            >
                <Select
                    disabled={!activity}
                    placeholder={"Select Metadata Type"}
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                        handleSelectedOption(detail.selectedOption)
                    }
                    options={[
                      { label: "AFS_ACTIVITY_ITEMS", value: "AFS_ACTIVITY_ITEMS" },
                      { label: "INTENT", value: "INTENT" },
                      { label: "ALEXA_INFO_LLM_TRACE", value: "ALEXA_INFO_LLM_TRACE" },
                      { label: "FLARE_REWRITE_ANNOTATION", value: "FLARE_REWRITE_ANNOTATION" },
                      { label: "RECOGNITION", value: "RECOGNITION" },
                      { label: "DIALOG", value: "DIALOG" },
                      { label: "TTS_GENERATED_RESPONSE", value: "TTS_GENERATED_RESPONSE" },
                      { label: "ANNOTATION", value: "ANNOTATION" },
                      { label: "DIALOG_REQUEST_ID", value: "DIALOG_REQUEST_ID" },
                      { label: "CLOUD_SIDE_VERIFICATION", value: "CLOUD_SIDE_VERIFICATION" },
                      { label: "AGENT", value: "AGENT" },
                      { label: "EVI_UNDERSTAND", value: "EVI_UNDERSTAND" },
                      { label: "EVI_SUITABILITY", value: "EVI_SUITABILITY" },
                      { label: "PANDA", value: "PANDA" },
                      { label: "EVI_ANSWER", value: "EVI_ANSWER" },
                      { label: "FEDERATE_ANSWERS", value: "FEDERATE_ANSWERS" }
                    ]}
                />
            </FormField>
            <Container disableContentPaddings>
                <ReactJson style={{wordBreak: 'break-all'}} src={metadata || {}}/>
            </Container>
        </SpaceBetween>
    );
}

export default React.memo(Metadata);
