import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';
import {
  Box,
  Button,
  SpaceBetween,
  Badge,
  Link,
  Container
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import ReactJson from '@microlink/react-json-view';

function Api({ device }) {
  const scroll = useRef();
  const [userScroll, setUserScroll] = useState(false);
  const [messages, setMessages] = React.useState([]);
  const [logs, setLogs] = React.useState(null);

  useEffect(() => {
    if (device) {
      const unsub = device.avsapi().subscribe(stateChange);
      return () => unsub();
    }
  }, [device]);

  useLayoutEffect(() => {
    if (scroll && scroll.current) {
      const ref = scroll.current;
      const handleScroll = () => {
        // Debug this
        setUserScroll(ref.scrollTop < ref.scrollHeight - ref.clientHeight);
      };
      ref.addEventListener('scroll', handleScroll);
      return () => {
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  });

  useLayoutEffect(() => {
    if (!userScroll && scroll.current) {
      scroll.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [userScroll, messages]);

  const stateChange = (d) => {
    setMessages([...d]);
  }

  const bubble = (badge, color, status, content, float) => {
    const header =  content.namespace + "." + content.name;
    return (
      <SpaceBetween direction="horizontal" size="xs">
        {float === 'left' && (<Badge color={color}> {badge} </Badge>)}
        <Box color={status}>
          <Link onFollow={(event) => {
            event.preventDefault();
            setLogs(content.data);
          }}>
            {header}
          </Link>
        </Box>
        {float === 'right' && (<Badge color={color}> {badge} </Badge>)}
      </SpaceBetween>
    );
  }

  return (
      <Container
          fitHeight
          disableHeaderPaddings
          disableContentPaddings
          header={
            <Box textAlign="center" variant="h2" padding="xs" margin="xxxs">
              API Logs
            </Box>
          }
      >
        <div id="apicontent" style={{
          height: '84vh',
          border: '1px solid #d5dbdb',
          borderTop: '0px',
          overflowY: 'auto',
          backgroundColor: '#f2f3f3',
          padding: '10px'
        }}>
          { logs ? (
              <SpaceBetween direction="vertical" size="m">
                <Box float="right"><Button iconName="close" iconAlign="right" onClick={() => setLogs(null)}/></Box>
                <ReactJson style={{wordBreak: 'break-all'}} src={logs}/>
              </SpaceBetween>
          ) : (
              <div>
                <SpaceBetween direction="vertical" size="m">
                  {messages.length != 0 && messages.map((item, index) => (
                    <React.Fragment key={`${item.type}_${index}`}>
                      {item.type === 'event' && (
                        <Box>
                          {bubble("e", "grey", "text-status-info", item, "left")}
                        </Box>
                      )}
                      {item.type === 'directive' && (
                        <Box float="right">
                          {bubble("d", "grey", "text-status-success", item, "right")}
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </SpaceBetween>
                <span ref={scroll} />
              </div>
          )}
        </div>
    </Container>
  )
}

export default React.memo(Api);
