import _ from 'lodash';
import { params, text } from "./util";
import { HexClient } from './client';
import { logToConsole } from "../../Util";
import { Item, TYPE } from "./history";

const size = 25;

export const STATE = [ "RECOGNIZE", "STOPCAPTURE", "SPEAK", "SPEECHFINISHED" ];

export function AvsApi(dsn, deviceType, history) {
    let close = null;
    let messages = [];
    let subscribers = [];
    let seen =  new Array(STATE.length).fill(0);
    let countfinish = 0;

    this.dsn = dsn;
    this.type = deviceType;
    this.history = history;
    this.messages = () => messages;

    const parse = (type, message) => {
      let parsed = [];
      let data = JSON.parse(_.get(message, "body"));
      data.map(item => {
        parsed.push({
          type: type,
          namespace: _.get(item, `${type}.header.namespace`),
          name: _.get(item, `${type}.header.name`),
          data: item
        });
      });
      return parsed;
    }

    const updateSeen = (val) => {
        const index = STATE.indexOf(val);
        if (index !== -1) {
            seen[index] = 1;
        }
    }

    const getSeen = (val) => {
        const index = STATE.indexOf(val);
        if (index !== -1) {
            return seen[index];
        }
        return -1;
    }

    const onspeak = (messages) => {
        let speak = '';
        let count = 0;
        messages.map(item => {
            if (item.name.toUpperCase() === "SPEAK") {
                let input = _.get(item.data, 'directive.payload.ssml');
                speak += text(input);
                count++;
            }
        })
        if (speak) {
            const item = new Item(speak, TYPE.ALEXA);
            this.history.addItem(item);
        }
        return count;
    }

    const handlers = (messages) => {
        if (messages.some(item => item.name.toUpperCase() === "RECOGNIZE")) {
            updateSeen("RECOGNIZE");
        }
        if (messages.some(item => item.name.toUpperCase() === "STOPCAPTURE")) {
            updateSeen("STOPCAPTURE");
        }
        if (messages.some(item => item.name.toUpperCase() === "SPEAK")) {
            updateSeen("SPEAK");
            countfinish = onspeak(messages);
        }
        if (messages.some(item => item.name.toUpperCase() === "SPEECHFINISHED")) {
            countfinish--;
            if (countfinish === 0) {
                updateSeen("SPEECHFINISHED");
            }
        }
    }

    const onmessage = (message) => {
      let type = _.get(message, "header.:event-type.value").split(':').pop().toLowerCase();
      if (type !== 'keep_alive') {
        const parsed = parse(type, message);

        if (messages.length === size) {
            messages.splice(0, parsed.length);
        }
        parsed.map(item => messages.push(item));
        publish(messages);

        handlers(parsed);
      }
    }

    const publish = (data) => {
        subscribers.forEach(s => s(data));
    };

    const wait = async (val, timeout, nothrow = false) => {
        let elapsed = 0;
        while (!val.some((v) => getSeen(v) === 1)) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            elapsed += 500;
            if (elapsed >= timeout) {
                if (nothrow) {
                    break;
                } else {
                    throw new Error(`Wait timeout exceeded for ${val}`);
                }
            }
        }
        return true;
    }

    const reset = () => {
        countfinish = 0;
        seen =  new Array(STATE.length).fill(0);
    }

    this.injectspeech = async (text, env, addhistory = true) => {
        const item = new Item(text, TYPE.PENDING);
        try {
            addhistory && this.history.addItem(item);

            const query = params(this.dsn, this.type, {'text': `<speak><prosody rate="slow">${text}</prosody></speak>`});
            // Always wait 3 seconds to prevent race
            !seen.every(bit => bit === 0) && await new Promise((resolve) => setTimeout(resolve, 3000));

            reset();
            const resp = await HexClient.injectspeech(query, env);

            if (_.get(resp, 'status') === 200) {
                // Wait to see if device woke up
                await wait(["RECOGNIZE", "STOPCAPTURE", "SPEAK", "PLAY", "STOP"], 10000);
                addhistory && this.history.updateItem({id: item.id, type: TYPE.PROCESSING});

                // Wait to see if there was a response and speech finished for response
                await wait(["SPEAK"], 25000) && await wait(["SPEECHFINISHED"], 30000, true);
                addhistory && this.history.updateItem({id: item.id, type: TYPE.HUMAN});

                reset();
                return item.id;
            }
        } catch (err) {
            logToConsole(`Cannot inject speech on device ${err}`);
        }
        addhistory && this.history.updateItem({id: item.id, type: TYPE.FAILED});
        reset();
        return -1;
    }

    this.initialize = async () => {
      if (!close) {
        try {
            const param = params(this.dsn, this.type);
            close = await HexClient.liveinteraction(param, onmessage);
        } catch (err) {
          logToConsole(`Cannot start device stream ${err}`);
        }
      }
    }

    this.clear = async () => {
      if (close) {
        await close();
        close = null;
      }
    }

    this.subscribe = (callback) => {
        subscribers.push(callback);

        // Return unsubscribe
        return () => {
            const index = subscribers.indexOf(callback);
            if (index !== -1) {
                subscribers.splice(index, 1);
            }
        };
    };

}
