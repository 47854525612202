import React, {useEffect, useState} from "react";
import {
  SpaceBetween,
  Input,
  Container,
  Button,
  Grid,
  FormField,
  Box,
  Flashbar,
  Spinner
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { findLast } from 'lodash';
import { TYPE } from "./history";
import Suggest from "./suggest";

const READY = "Ready";

function Panel({ device }) {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState();
  const [invalid, setInvalid] = useState(false);
  const [settings, setSettings] = useState();
  const [mode, setMode] = useState();
  const [message, setMessage] = useState(READY);

  useEffect(async () => {
    // An utterance is not in progress, settings and mode are defined and mode is sugegst turned on
    if (!loading && settings && mode && mode.suggest) {
      setMessage("Generating suggestion...");
      const suggestion = await device.suggest(settings.prompt, settings.option.value);
      input || setInput(suggestion);
      setMessage(READY);
    }
  }, [mode, loading, settings]);

  useEffect(async () => {
    // An utterance is not in progress, settings and mode are defined and mode is sugegst turned on
    if (!loading && mode && mode.auto && input) {
      setMessage("Sending suggestion...");
      await new Promise(resolve => setTimeout(resolve, 3000));
      handleSend();
    }
  }, [mode, loading, input]);

  useEffect(() => {
    if (!loading) {
      setMessage(READY);
    }
  }, [loading]);

  useEffect(() => {
    if (device) {
      const unsub = device.history().subscribe(stateChange);
      return () => unsub();
    }
  }, [device]);

  const stateChange = (h) => {
    setLoading((loading) => {
      if (loading) {
        let last = findLast(h.history(), item => item.type !== TYPE.ALEXA);
        return last && [TYPE.PENDING, TYPE.PROCESSING].includes(last.type);
      }
      return loading;
    });
  }

  const handleSend = (e) => {
    e && e.preventDefault();
    if (input && input.match(/\b\w+\b/g).length > 0) {
      setLoading(true);
      setMessage("Sending utterance...");
      device.injectspeech(input);
      setMessage("Timed wait for Speak directive & SpeechFinished event...");
      setInput(null);
    } else {
      setInvalid(true);
    }
  }

  return (
    <Container disableContentPaddings header={
      <SpaceBetween direction="vertical" size="m">
        <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
          <FormField stretch>
            <Input
              spellCheck
              autoFocus
              onChange={(e) => {
                setInvalid(false);
                setInput(e.detail.value);
              }}
              onKeyDown={(e) => {
                if (e.detail.key === "Enter" && !e.shiftKey && !loading) {
                  handleSend(e);
                }
              }}
              invalid={invalid}
              value={input}
              placeholder={"Ask Alexa"}
              disabled={mode && mode.auto}
            />
          </FormField>
          <FormField stretch>
            <Box textAlign="center">
              <Button
                wrapText={false}
                variant="primary"
                onClick={handleSend}
                loading={loading}
                disabled={mode && mode.auto}
              > Send </Button>
            </Box>
          </FormField>
        </Grid>
        <Suggest settings={setSettings} mode={setMode}/>
        <Flashbar items={[
          {
            type: "info",
            loading: (message !== READY),
            dismissible: false,
            content: (message),
            id: "message"
          }
        ]} />
      </SpaceBetween>}
    />
  )
}

export default React.memo(Panel);
