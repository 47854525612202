import axios from 'axios';
import { EventStreamCodec } from '@aws-sdk/eventstream-codec';
import { fromUtf8, toUtf8 } from '@aws-sdk/util-utf8-browser';
import { aqtStore } from "../../Components/State/Store";
import { v4 as uuidv4 } from 'uuid';
import { logToConsole } from "../../Util";

const esCodec = new EventStreamCodec(toUtf8, fromUtf8);
const decoder = new TextDecoder();

const HexClientProxy = () => {

  function url(path, params) {
    return `${endpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function gammaUrl(path, params) {
    return `${gammaEndpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function authHeader() {
    return { 'x-amz-identity-token':  aqtStore.getState().session.idToken.jwtToken };
  }

  function endpoint() {
    return aqtStore.getState().environment.aqt_hex;
  }

  function gammaEndpoint() {
    return aqtStore.getState().environment.aqt_hex_gamma;
  }

  async function get(path, params) {
    try {
      return await axios.get(url(path, params), {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function getFromGamma(path, params) {
    try {
      return await axios.get(gammaUrl(path, params), {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function put(path, params) {
    try {
      return await axios.put(url(path, params), null, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function stream(path, params, onMessage) {
    try {
      const abortController = new AbortController();
      const { signal } = abortController;
      const response = await fetch(url(path, params), {headers: authHeader(), signal});

      if (!response.ok) {
        throw new Error(`HTTP stream error! Status: ${response.status}`);
      }

      const reader = response.body.getReader();

      const processChunks = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();

            // This avoids event stream decode when rapid events arrive
            new TextDecoder().decode(value);

            if (done) {
              break;
            }

            try {
              const arr = new Uint8Array(value);
              const message = esCodec.decode(arr);

              onMessage({
                header: message.headers,
                body: decoder.decode(message.body),
              });
            } catch (error) {}
          }
        } catch (error) {
          logToConsole(`Error processing chunks ${error}`);
        } finally {
          reader.releaseLock();
        }
      };

      processChunks();

      return async () => {
        abortController.abort();
      }
    } catch(err) {
      throw new Error(err);
    }
  }

  return {
    ping: async function() {
      return await get('ping');
    },
    oakauth: async function (params) {
      return await get('oakauth', params);
    },
    deviceaccounts: async function (params) {
      return await get('deviceaccounts', params);
    },
    bedrockgenerate: async function (params) {
      return await get('bedrockgenerate', params);
    },
    injectspeech: async function (params, env) {
      if(env == "prod")
        return await get('injectspeech', params);
      else
        return await getFromGamma('injectspeech', params);      
    },
    activityitems: async function (params) {
      return await get('activityitems', params);
    },
    alexametadata: async function (params) {
      return await get('alexametadata', params);
    },
    deviceendpoint: async function (params, env) {
      if(env == "prod")
        return await get('deviceendpoint', params);
      else
        return await getFromGamma('deviceendpoint', params);
    },
    switchendpoint: async function (params) {
      return await put('deviceendpoint', params);
    },
    liveinteraction: function (params, onMessage) {
      return stream('liveinteraction', params, onMessage);
    }
  }
}

export const AbesClientProxy = () => {

  function url(path, params) {
    return `${endpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function authHeader() {
    return { Authorization: `Bearer ${aqtStore.getState().session.idToken.jwtToken}` };
  }

  function endpoint() {
    return aqtStore.getState().environment.controllerEndpoint;
  }

  async function post(path, params, payload) {
    try {
      return await axios.post(url(path, params), payload, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function mqttmessage(params, payload) {
    return await post('api/mqtt/message', params, payload);
  }

  return {
    sdcfeature: async function (payload) {
      const id = uuidv4();
      const payloadplus = {
        requestTopic: `aqtservice-usamazon/sdcfeature/${id}/request`,
        responseTopic: `aqtservice-usamazon/sdcfeature/${id}/response`,
        payload
      };
      return await mqttmessage(new URLSearchParams({ waitForActionTime: 10000 }), payloadplus);
    },
    senddirective: async function (payload) {
      const id = uuidv4();
      const payloadplus = {
        requestTopic: `aqtservice-usamazon/senddirective/${id}/request`,
        responseTopic: `aqtservice-usamazon/senddirective/${id}/response`,
        payload
      };
      return await mqttmessage(new URLSearchParams({ waitForActionTime: 10000 }), payloadplus);
    }
  }
  
}

export const HexClient = HexClientProxy();
export const AbesClient = AbesClientProxy();
