export default [
  {
    text: 'Configure Labs',
    href: '/labs',
    type: 'link'
  },
  {
    text: 'Test Center',
    href: '/v2/run/draft',
    type: 'link'
  },
  {
    text: 'Live Runs',
    href: '/liveRuns',
    type: 'link'
  },
  {
    text: 'Test Library',
    href: '/customTests',
    type: 'link'
  },
  {
    text: 'Playground',
    href: '/playground',
    type: 'link'
  }
];
